import React from 'react';
import Button from '@material-ui/core/Button';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import logo from './ICON-logo.png'

export default class extends React.Component {
    render() {
        return (
            <div className="outer">
                <div className="middle">
                    <div className="inner panel-div">
                        <img src={logo} alt="logo" height="128px" width="128px" />
                        <h1>Welcome to the NCIM Membership Admin Portal</h1>
                        <p>To access the portal, please click on the Login button below</p><br/>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<LockOpenIcon />}
                            onClick={()=>{this.props.login()}}
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}