import React from 'react';
import axios from 'axios';
import { withAuth } from '@okta/okta-react';
import BraftEditor from "braft-editor";
import Table from "braft-extensions/dist/table";
import Markdown from "braft-extensions/dist/markdown";
import { baseURL } from './index';

BraftEditor.use(Markdown());
BraftEditor.use(Table());

export default withAuth(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const initialState = {
            subject: '',
            message: BraftEditor.createEditorState(null)
        };
        return initialState;
    }

    sendEmail = outputObj => {
        let variables = {
            to_email: 'ncimmem@ncimusa.org',
            bcc_emails: outputObj.bcc_emails,
            subject: outputObj.subject,
            message: outputObj.message
        };
        window.emailjs.send(
            'smtp_server', 'template_general',
            variables, 'user_vJYu4sOkFQUTet8DZPWD7'
        ).then(res => {
            console.log('Email successfully sent!')
            alert("Email successfully sent!")
            this.setState(this.getInitialState())
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with sending the email! Contact your Administrator");
        });
    };

    handleSubmit = async(e) => {
        e.preventDefault();

        let outputObj = { ...this.state };

        if (this.props.mode === 'custom') {
            outputObj.bcc_emails = this.props.bcc_emails.join(', ');

            outputObj.message = outputObj.message.toHTML();

            this.sendEmail(outputObj);
            return;
        }

        let members = [];
        let bcc_emails = [];

        const accessToken = await this.props.auth.getAccessToken();
        const response = axios.get(
            `${baseURL}/members`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        response.then(res => {
            members = res.data;

            members.map(o => {
                return bcc_emails.push(o.email)
            });

            outputObj.bcc_emails = bcc_emails.join(', ');

            outputObj.message = outputObj.message.toHTML();

            this.sendEmail(outputObj);
        });
        response.catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with the Database! Contact your Administrator");
        });
    };

    render() {
        return (
            <form className="registration-form" onSubmit={this.handleSubmit}>
                <div>
                    <p>
                        <label>Subject</label><span style={{ color: 'red' }}> *</span><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="SUBJECT"
                            required
                            value={this.state.subject}
                            onChange={e => this.setState({ subject: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Message</label><span style={{ color: 'red' }}> *</span><br />
                        <BraftEditor
                            value={this.state.message}
                            onChange={e => this.setState({ message: e })}
                            placeholder={"Write here..."}
                            language="en"
                            contentStyle={{
                                minHeight: 210,
                                boxShadow: "inset 0 1px 3px rgba(0,0,0,.1)",
                            }}
                        />
                        <br />
                    </p>

                    <div style={{ display: 'table' }}>
                        <span style={{ display: 'table-cell' }}>
                            <input className="register-button" type="submit" value="Send" />
                        </span>
                    </div>
                </div>
            </form>
        )
    }
});