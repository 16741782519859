import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Fab } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import RegistrationForm from './RegistrationForm'
import MembersDashboard from './MembersDashboard'
import GroupEmail from './GroupEmail'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function NavigationTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <span>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="primary"
          centered
        >
          <Tab label="Registration Form" />
          <Tab label="Members" />
          <Tab label="Public Email" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <div className="panel-div">
          <RegistrationForm />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="panel-div" style={{width: '100%'}}>
          <MembersDashboard />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="panel-div">
          <GroupEmail />
        </div>
      </TabPanel>
      <Fab color="secondary" className="fab" aria-label="log-out" onClick={ ()=>{props.logout()} }>
        <ExitToAppIcon />
      </Fab>
    </span>
  );
}