const checkboxes = [
  {
    name: 'Building Committee',
    key: 'checkBox1',
    label: 'Building Committee',
  },
  {
    name: 'Social Committee',
    key: 'checkBox2',
    label: 'Social Committee',
  },
  {
    name: 'Educational Committee',
    key: 'checkBox3',
    label: 'Educational Committee',
  },
  {
    name: 'Business Network Committee',
    key: 'checkBox4',
    label: 'Business Network Committee',
  },
  {
    name: 'Nigerian Day Committee',
    key: 'checkBox5',
    label: 'Nigerian Day Committee',
  },
  {
    name: 'Scholarship Award Committee',
    key: 'checkBox6',
    label: 'Scholarship Award Committee',
  },
  {
    name: 'Hospitality Committee',
    key: 'checkBox7',
    label: 'Hospitality Committee',
  },
  {
    name: '*Association Bylaws Committee',
    key: 'checkBox8',
    label: '*Association Bylaws Committee',
  },
];

export default checkboxes;