import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, ImplicitCallback } from '@okta/okta-react';

import './App.css';
import Home from './components/Home';

const config = {
  issuer: 'https://dev-991539.okta.com/oauth2/default',
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: '0oa2c2qwafoMm0KDq357',
  pkce: true
}

function App() {
  return (
    <Router>
      <Security {...config}>
        <Route path='/' exact={true} component={Home}/>
        <Route path='/implicit/callback' component={ImplicitCallback}/>
      </Security>
    </Router>
  );
}

export default App;
