import React from "react";
import axios from 'axios';
import MaterialTable from "material-table";
import { Button, Menu, MenuItem, Modal } from '@material-ui/core';
import { withAuth } from '@okta/okta-react';

import { baseURL } from './index';
import GroupEmail from "./GroupEmail";

export default withAuth(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.raiseAlert = false;
    }

    componentDidMount() {
        this.refreshState();
    }

    refreshState = async () => {
        let emailFormDiv = document.getElementById('email-form');
        let paymentFormDiv = document.getElementById('payment-form');
        emailFormDiv.style.display = 'none';
        paymentFormDiv.style.display = 'none';

        this.setState(this.getInitialState());

        const accessToken = await this.props.auth.getAccessToken();
        const data_response = axios.get(
            `${baseURL}/members`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        data_response.then(res => {
            this.setState({ data: res.data });
        });
        data_response.catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with the Database! Contact your Administrator");
        });

        const active_response = axios.get(
            `${baseURL}/active-members`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        active_response.then(res => {
            this.setState({ active_members: res.data });
        });
        active_response.catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with the Database! Contact your Administrator");
        });
    };

    getInitialState = () => {
        const initialState = {
            selectedRow: null,
            columns: [
                { title: "Amount Due", field: "amount_due", type: "numeric", editable: "never" },
                {
                    title: 'Active Member',
                    field: 'active_member',
                    lookup: { 0: 'Non-Active', 1: 'Active' },
                },
                { title: "Last Name", field: "last_name" },
                { title: "First Name", field: "first_name" },
                { title: "Middle Initials", field: "middle_int" },
                { title: "Title", field: "title" },
                { title: "# of Family members", field: "num_family_members", type: "numeric" },
                { title: "Spouse's Name", field: "spouse_name" },
                { title: "Children's Name", field: "children_names" },
                { title: "Email Address", field: "email" },
                { title: "Second Email", field: "second_email" },
                { title: "Home Phone", field: "home_phone" },
                { title: "Cell Phone", field: "cell_phone" },
                {
                    title: "Address",
                    field: "address",
                    cellStyle: {
                        minWidth: '200px'
                    },
                    headerStyle: {
                        minWidth: '200px'
                    }
                },
                {
                    title: "Elected Committees",
                    field: "committees",
                    cellStyle: {
                        minWidth: '200px'
                    },
                    headerStyle: {
                        minWidth: '200px'
                    }
                },
                { title: "Building Committee Days", field: "building_committee_day" }
            ],
            data: [],
            active_members: [],
            bcc_emails: [],
            checkedRows: [],
            membersGroup: '',
            anchorEl: null,
            payment_amount: "0",
            openModal: false
        };
        return initialState;
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    openModal = () => {
        this.setState({openModal: true});
    };

    closeModal = () => {
        this.setState({openModal: false});
    };

    sendEmail = outputObj => {
        let variables = {
            to_email: outputObj.email,
            bcc_emails: '',
            subject: outputObj.subject,
            message: outputObj.message
        };
        window.emailjs.send(
            'smtp_server', 'template_general',
            variables, 'user_vJYu4sOkFQUTet8DZPWD7'
        ).then(res => {
            console.log('Email successfully sent!')
            if (this.raiseAlert) {
                alert('Email successfully sent!')
                this.raiseAlert = false;
            }
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with sending the email! Contact your Administrator");
        });
    };

    sendDeletionEmail = oldData => {
        let outputObj = {
            email: oldData.email,
            subject: 'Cancellation Notice',
            message: `
            <p>Dear ${oldData.title} ${oldData.last_name},</p>
            <p>This email serves as a notice to notify you that you have been removed from the membership list at the Nigerian Community in Milwaukee.</p>
            <p>
              <br>
            </p>
            <p>If you believe this is an error, be sure to reply to this email stating as such.</p>
            <p>
              <br>
            </p>
            <p>Best wishes,
              <br>NCIM Executive
            </p>
            `
        };
        this.sendEmail(outputObj);
    };

    sendAmountDueInvoice = currentBalance => {
        let outputObj = {
            email: this.state.selectedRow.email,
            subject: 'INVOICE: There has been a change in your Balance Due',
            message: `
            <p>Dear ${this.state.selectedRow.title} ${this.state.selectedRow.last_name},</p>
            <p>Thank you for your NCIM membership payment.<br/>Your membership Dues is $${currentBalance}.</p>
            <p>
              <br/>
            </p>
            <p>You have paid $${200 - currentBalance} towards your balance</p>
            <p>
              <br/>
            </p>
            <p>Best wishes,
              <br>NCIM Account Office
            </p>
            `
        };
        this.sendEmail(outputObj);
    };

    sendEmailChangeNotice = (newData, oldData) => {
        if (newData === null) return;
        if (newData.email === oldData.email) return;

        let outputObj = {
            email: [newData.email, oldData.email].join(', '),
            subject: 'EMAIL CHANGE: There has been a change to registered Email',
            message: `
            <p>Dear ${newData.title} ${newData.last_name},</p>
            <p>This email serves as notification that your registered membership email with the Nigerian Community in Milwaukee has been changed. Below is your new email:</p>
            <p style="text-align: center;">${newData.email}</p>
            <p>Best wishes,
              <br>NCIM Executive
            </p>
            `
        };
        this.sendEmail(outputObj);
    };

    sendPaymentReminderEmail = () => {
        let outputObj = {
            email: this.state.selectedRow.email,
            subject: 'Payment Reminder',
            message: `
            <p><b>Dear ${this.state.selectedRow.title} ${this.state.selectedRow.last_name},</b></p>
            <p>This is a reminder email to notify you that your NCIM Membership is due January 1st. Please remember to send in your payment.<br/><br/>
            You are very valuable to the NCIM community.<br/>You are greatly appreciated.<br/><br/>
            Many thanks,<br/>
            NCIM Executive
            </p>
            `
        };
        this.raiseAlert = true;
        this.sendEmail(outputObj);
    };

    sendGroupEmailToCustomMembersList = membersList => {
        let bcc_emails = [];
        membersList.map(o => {
            return bcc_emails.push(o.email)
        });
        this.setState({ bcc_emails: bcc_emails });

        let emailFormDiv = document.getElementById('email-form');
        let paymentFormDiv = document.getElementById('payment-form');
        emailFormDiv.style.display = 'block';
        paymentFormDiv.style.display = 'none';
    };

    compareDataChanges = (myObj1, myObj2) => {
        // find keys
        let keys = Object.keys(myObj1);
        let result = [];

        // find values
        keys.map(key => {
            if (myObj1[key] !== myObj2[key]) {
                result.push(key)
            }
            return 0
        });

        return result;
    };

    updateMember = async (newData, oldData) => {
        let postData = [];
        postData[0] = newData['id'];
        let changedColumns = this.compareDataChanges(newData, oldData);
        let changedObject = {};
        changedColumns.map(key => {
            if (!(["id", "tableData"].includes(key))) {
                changedObject[key] = newData[key];
            }
            postData[1] = changedObject;
            return 0;
        });

        if (Object.keys(postData[1]).length === 0) {
            return;
        }

        const accessToken = await this.props.auth.getAccessToken();
        const response = axios.post(
            `${baseURL}/update-member`,
            postData,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        response.then(res => {
            this.sendEmailChangeNotice(newData, oldData);
            this.refreshState();
        });
        response.catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with the Database! Contact your Administrator");
        });
    };

    deleteMember = async (oldData) => {
        const accessToken = await this.props.auth.getAccessToken();
        const response = axios.post(
            `${baseURL}/delete-member`,
            oldData,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        response.then(res => {
            this.sendDeletionEmail(oldData);
            this.refreshState();
        });
        response.catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with the Database! Contact your Administrator");
        });
    };

    showPaymentForm = () => {
        let emailFormDiv = document.getElementById('email-form');
        let paymentFormDiv = document.getElementById('payment-form');
        emailFormDiv.style.display = 'none';
        paymentFormDiv.style.display = 'block';
    };

    makePayment = async () => {
        if (parseInt(this.state.payment_amount) > parseInt(this.state.selectedRow.amount_due)) {
            alert(`The payment amount entered is more than the Amount Balance for ${this.state.selectedRow.title} ${this.state.selectedRow.last_name}`);
            return;
        }
        if (this.state.payment_amount === "0") {
            alert(`Enter an amount greater than 0`);
            return;
        }

        let body = {
            id: this.state.selectedRow.id,
            old_balance: this.state.selectedRow.amount_due,
            payment_amount: this.state.payment_amount
        }

        const accessToken = await this.props.auth.getAccessToken();
        const response = axios.post(
            `${baseURL}/make-payment`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        response.then(res => {
            let paymentFormDiv = document.getElementById('payment-form');
            paymentFormDiv.style.display = 'none';

            this.sendAmountDueInvoice(parseInt(body.old_balance) - parseInt(body.payment_amount));
            this.refreshState();
        });
        response.catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with the Database! Contact your Administrator");
        });
    };

    resetActiveMembersBalance = async () => {
        const accessToken = await this.props.auth.getAccessToken();
        const response = axios.post(
            `${baseURL}/reset-balance`, {},
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        response.then(res => {
            alert("The Amount Balance for all Active Members have been set to $200");
            this.refreshState();
        });
        response.catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with the Database! Contact your Administrator");
        });
    };

    downloadCSVFromJson = filename => {
        let arrayOfJson = JSON.parse(JSON.stringify(this.state.data));
        arrayOfJson.map(o => {
            delete o.tableData;
            return 0;
        });
        // convert JSON to CSV
        const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        const header = Object.keys(arrayOfJson[0])
        let csv = arrayOfJson.map(row => header.map(fieldName =>
            JSON.stringify(row[fieldName], replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')

        // Create link and download
        var link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    render() {
        return (
            <span>
                <MaterialTable
                    title=""
                    columns={this.state.columns}
                    data={this.state.data}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                        }, () => { this.updateMember(newData, oldData) });
                                    }
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return { ...prevState, data };
                                    }, () => {
                                        this.deleteMember(oldData);
                                    });
                                }, 600);
                            })
                    }}
                    onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                    options={{
                        headerStyle: {
                            backgroundColor: '#12bd54',
                            color: '#FFF'
                        },
                        searchFieldStyle: {
                            color: '#FFF'
                        },
                        rowStyle: rowData => ({
                            backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#12bd54' : 'transparent',
                            color: '#fff'
                        }),
                        selection: true
                    }}
                    onSelectionChange={(rows) => this.setState({ checkedRows: rows })}
                />
                <div style={{ padding: '20px 0px', textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        Actions
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem
                            onClick={() => {
                                this.showPaymentForm();
                                this.handleClose();
                            }}
                            disabled={(this.state.selectedRow && this.state.selectedRow.amount_due > 0) ? false : true}
                        >
                            Make a Payment for {(this.state.selectedRow) ? `${this.state.selectedRow.title} ${this.state.selectedRow.last_name}` : ""}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.sendPaymentReminderEmail();
                                this.handleClose();
                            }}
                            disabled={(this.state.selectedRow && this.state.selectedRow.amount_due > 0) ? false : true}
                        >
                            Send Payment Reminder to {(this.state.selectedRow) ? `${this.state.selectedRow.title} ${this.state.selectedRow.last_name}` : ""}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.setState({ membersGroup: 'selected' });
                                this.sendGroupEmailToCustomMembersList(this.state.checkedRows);
                                this.handleClose();
                            }}
                            disabled={(this.state.checkedRows.length) ? false : true}
                        >
                            Send Email to Selected Members
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.setState({ membersGroup: 'active' });
                                this.sendGroupEmailToCustomMembersList(this.state.active_members);
                                this.handleClose();
                            }}
                            disabled={(this.state.active_members.length) ? false : true}
                        >
                            Send Email to Active Members
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.openModal();
                                this.handleClose();
                            }}
                        >
                            RESET Active Member Balance
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.downloadCSVFromJson('ncim-member-data.csv');
                                this.handleClose();
                            }}
                            disabled={(this.state.data.length) ? false : true}
                        >
                            Download Member Data
                        </MenuItem>
                    </Menu>
                </div>
                <div id='email-form' style={{ padding: '20px 0px', textAlign: 'center', width: '75%', margin: 'auto', display: 'none' }}>
                    <h5>
                        This Email will be sent to {(this.state.membersGroup === 'active') ?
                            'only the Active members' : `the following addresses: ${this.state.bcc_emails.join(', ')}`}
                    </h5>
                    <GroupEmail mode='custom' bcc_emails={this.state.bcc_emails} />
                </div>
                <div id='payment-form' style={{ padding: '20px 0px', textAlign: 'center', width: '75%', margin: 'auto', display: 'none' }}>
                    <h5>
                        Making a Payment for {(this.state.selectedRow) ? `${this.state.selectedRow.title} ${this.state.selectedRow.last_name}` : ""} <br />
                        *Current Balance is ${(this.state.selectedRow) ? this.state.selectedRow.amount_due : ""}*
                    </h5>
                    <label>Payment Amount: </label>
                    <input
                        className="input-styling"
                        type="number"
                        min="0"
                        name="PAYMENT"
                        required
                        style={{ width: '50px' }}
                        value={this.state.payment_amount}
                        onChange={e => this.setState({ payment_amount: e.target.value })}
                    />
                    <div style={{ paddingTop: '15px' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            aria-haspopup="true"
                            onClick={this.makePayment}
                        >
                            Pay
                        </Button>
                    </div>
                </div>
                <div>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.openModal}
                        onClose={this.closeModal}
                    >
                        <div className="modal">
                            <h2 id="simple-modal-title">Are you SURE you want to reset the Balance Amount Due?</h2>
                            <p id="simple-modal-description">
                                If you click YES, you will be resetting the Amount balance for all active members to $200. Click NO if you do not want to make the reset.
                            </p>
                            <div style={{textAlign: 'center'}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    aria-haspopup="true"
                                    style={{backgroundColor: '#00c739'}}
                                    onClick={()=>{
                                        this.resetActiveMembersBalance();
                                    }}
                                >
                                    YES
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    aria-haspopup="true"
                                    style={{backgroundColor: '#da2a2a', marginLeft: '10px'}}
                                    onClick={()=>{
                                        this.closeModal();
                                    }}
                                >
                                    NO
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </span>
        );
    }
});