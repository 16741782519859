import React from 'react';
import axios from 'axios';
import { withAuth } from '@okta/okta-react';

import checkboxes from './checkboxes';
import Checkbox from './CheckBox';
import { baseURL } from './index';

export default withAuth(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const initialState = {
            amount_due: 0,
            last_name: '',
            first_name: '',
            middle_int: '',
            title: '',
            num_family_members: '',
            spouse_name: '',
            children_names: '',
            email: '',
            second_email: '',
            home_phone: '',
            cell_phone: '',
            address: '',
            committees: new Map(),
            building_committee_day: 'N/A',
            active_member: 0
        };
        return initialState;
    };

    sendEmail = outputObj => {
        let variables = {
            to_name: `${outputObj.title} ${outputObj.last_name}`,
            to_email: outputObj.email,
            message_html: `
                <span>
                    <b>Amount Due:</b> $${outputObj.amount_due}<br />
                    <b>Last Name:</b> ${outputObj.last_name}<br />
                    <b>First Name:</b> ${outputObj.first_name}<br />
                    <b>Middle Initial:</b> ${outputObj.middle_int}<br />
                    <b>Title:</b> ${outputObj.title}<br />
                    <b>Number of Family Members:</b> ${outputObj.num_family_members}<br />
                    <b>Spouse's Name:</b> ${outputObj.spouse_name}<br />
                    <b>Children's Names:</b> ${outputObj.children_names}<br />
                    <b>Email:</b> ${outputObj.email}<br />
                    <b>Second Email:</b> ${outputObj.second_email}<br />
                    <b>Home Phone:</b> ${outputObj.home_phone}<br />
                    <b>Cell Phone:</b> ${outputObj.cell_phone}<br />
                    <b>Address:</b> ${outputObj.address}<br />
                    <b>Elected Committees:</b> ${outputObj.committees}<br />
                    <b>Building Committee Day <i>(if applicable)</i>:</b> ${outputObj.building_committee_day}
                </span>`
        };
        window.emailjs.send(
            'smtp_server', 'template_welcome',
            variables, 'user_vJYu4sOkFQUTet8DZPWD7'
        ).then(res => {
            console.log('Email successfully sent!')
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with sending the email! Contact your Administrator");
        });
    };

    handleCommitteeCheckBoxes = e => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ committees: prevState.committees.set(item, isChecked) }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        let outputObj = JSON.parse(JSON.stringify(this.state));
        outputObj.committees = [];
        for (let k of this.state.committees.keys()) {
            if (this.state.committees.get(k) === true)
                outputObj.committees.push(k);
        }
        outputObj.committees = outputObj.committees.join(', ');

        let members = [];

        const accessToken = await this.props.auth.getAccessToken();
        const response = axios.get(
            `${baseURL}/members`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        response.then(res => {
            members = res.data;

            if (members.find(o => o.email === outputObj.email) !== undefined) {
                alert("The entered email has already been entered for a member.");
                return;
            }

            axios.post(
                `${baseURL}/add-member`,
                outputObj,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            this.sendEmail(outputObj);
            this.setState(this.getInitialState());
            alert("Member has been successfully registered");
        });
        response.catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            alert("Oops... There was an error with the Database! Contact your Administrator");
        });
    };

    render() {
        return (
            <form className="registration-form" onSubmit={this.handleSubmit}>
                <div>
                    <p>
                        <label>Amount Due</label><span style={{ color: 'red' }}> *</span><br />
                        <input
                            className="input-styling"
                            type="number" name="AMOUNT" min="0"
                            placeholder="0"
                            required
                            value={this.state.amount_due}
                            onChange={e => this.setState({ amount_due: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Active Member</label><br />
                        <select
                            className="input-styling"
                            style={{ width: '100%' }} name="active-member"
                            value={this.state.active_member}
                            onChange={e => this.setState({ active_member: e.target.value })}
                        >
                            <option className="input-styling" value="1">Active</option>
                            <option className="input-styling" value="0">Non-Active</option>
                        </select>
                    </p>
                    <p>
                        <label>Last Name</label><span style={{ color: 'red' }}> *</span><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="LNAME"
                            required
                            value={this.state.last_name}
                            onChange={e => this.setState({ last_name: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>First Name</label><span style={{ color: 'red' }}> *</span><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="FNAME"
                            required
                            value={this.state.first_name}
                            onChange={e => this.setState({ first_name: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Middle Int</label><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="MMERGE8"
                            value={this.state.middle_int}
                            onChange={e => this.setState({ middle_int: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Title (Prefix)</label><span style={{ color: 'red' }}> *</span><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="MMERGE7"
                            placeholder="Mr./Mrs."
                            required
                            value={this.state.title}
                            onChange={e => this.setState({ title: e.target.value })}
                        />
                    </p>
                    <p>
                        <label># of family member including you</label><br />
                        <input
                            className="input-styling"
                            type="number"
                            name="MMERGE9"
                            value={this.state.num_family_members}
                            onChange={e => this.setState({ num_family_members: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Spouse's Name</label><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="SNAME"
                            value={this.state.spouse_name}
                            onChange={e => this.setState({ spouse_name: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>List Children's Names</label><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="MMERGE13"
                            placeholder="Child 1, Child 2, Child 3"
                            value={this.state.children_names}
                            onChange={e => this.setState({ children_names: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Email address</label><span style={{ color: 'red' }}> *</span><br />
                        <input
                            className="input-styling"
                            type="email" name="EMAIL"
                            placeholder="Your email address"
                            required
                            value={this.state.email}
                            onChange={e => this.setState({ email: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>2nd Email Address</label><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="MMERGE11"
                            placeholder="optional"
                            value={this.state.second_email}
                            onChange={e => this.setState({ second_email: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Home Phone</label><span style={{ color: 'red' }}> *</span><br />
                        <input
                            className="input-styling"
                            type="tel"
                            name="PHONE"
                            placeholder="(XXX) XXX-XXX"
                            required
                            value={this.state.home_phone}
                            onChange={e => this.setState({ home_phone: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Cell Phone</label><br />
                        <input
                            className="input-styling"
                            type="tel"
                            name="MMERGE12"
                            value={this.state.cell_phone}
                            onChange={e => this.setState({ cell_phone: e.target.value })}
                        />
                    </p>
                    <p>
                        <label>Address</label><span style={{ color: 'red' }}> *</span><br />
                        <input
                            className="input-styling"
                            type="text"
                            name="ADDRESS[addr1]"
                            placeholder="Street Address, City, State, Zip Code, Country"
                            value={this.state.address}
                            onChange={e => this.setState({ address: e.target.value })}
                        />
                    </p>
                    <p>
                        <b><i>
                            I will like to join one or more of the listed committee member: <br />
                            My goal is to contribute to the progress of the Nigerian Association In Milwaukee
                        </i></b>
                    </p>
                    <p>
                        <React.Fragment>
                            {checkboxes.map(item => (
                                <label key={item.key}>
                                    <Checkbox name={item.name} checked={this.state.committees.get(item.name)} onChange={this.handleCommitteeCheckBoxes} />
                                    <span> {item.name}</span>
                                    <br />
                                </label>
                            ))}
                        </React.Fragment>
                    </p>
                    <p>
                        <label>Building Committee Days</label><br />
                        <select
                            className="input-styling"
                            style={{ width: '100%' }} name="MMERGE5"
                            value={this.state.building_committee_day}
                            onChange={e => this.setState({ building_committee_day: e.target.value })}
                        >
                            <option className="input-styling" value="Monday">Monday</option>
                            <option className="input-styling" value="Tuesday">Tuesday</option>
                            <option className="input-styling" value="Wednesday">Wednesday</option>
                            <option className="input-styling" value="Thursday">Thursday</option>
                            <option className="input-styling" value="Friday">Friday</option>
                            <option className="input-styling" value="Saturday">Saturday</option>
                            <option className="input-styling" value="N/A">N/A</option>
                        </select>
                    </p>

                    <p>
                        <input className="register-button" type="submit" value="Register" />
                    </p>
                </div>
            </form>
        )
    }
});